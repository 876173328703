export const LIFESTYLES = [
  {
    id: 1,
    name: 'Business professionals',
    image: 'https://assets.leaseloco.com/inspiration/business/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/business/foreground.png',
    slug: '/car-leasing/business-professionals',
  },
  {
    id: 2,
    name: 'Cheap car leasing',
    image: 'https://assets.leaseloco.com/inspiration/cheap-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/cheap-car/foreground.png',
    slug: '/car-leasing/cheap-car-leasing',
  },
  {
    id: 3,
    name: 'City cars',
    image: 'https://assets.leaseloco.com/inspiration/city-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/city-car/foreground.png',
    slug: '/car-leasing/city-cars',
  },
  {
    id: 4,
    name: 'Convertibles',
    image: 'https://assets.leaseloco.com/inspiration/convertibles/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/convertibles/foreground.png',
    slug: '/car-leasing/convertible-car-leasing',
  },
  {
    id: 5,
    name: 'Dog friendly',
    image: 'https://assets.leaseloco.com/inspiration/dog-friendly/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/dog-friendly/foreground.png',
    slug: '/car-leasing/dog-friendly',
  },
  {
    id: 6,
    name: 'Family cars',
    image: 'https://assets.leaseloco.com/inspiration/family-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/family-car/foreground.png',
    slug: '/car-leasing/family-car-leasing',
  },
  {
    id: 7,
    name: 'Fuel-efficient cars',
    image: 'https://assets.leaseloco.com/inspiration/fuel-efficient/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/fuel-efficient/foreground.png',
    slug: '/car-leasing/fuel-efficient',
  },
  {
    id: 8,
    name: 'High mileage deals',
    image: 'https://assets.leaseloco.com/inspiration/high-mileage-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/high-mileage-car/foreground.png',
    slug: '/car-leasing/high-mileage-car-leasing',
  },
  {
    id: 9,
    name: 'People carriers',
    image: 'https://assets.leaseloco.com/inspiration/people-carriers/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/people-carriers/foreground.png',
    slug: '/car-leasing/mpv-car-leasing',
  },
  {
    id: 10,
    name: 'Performance cars',
    image: 'https://assets.leaseloco.com/inspiration/performance-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/performance-car/foreground.png',
    slug: '/car-leasing/performance-car-leasing',
  },
  {
    id: 11,
    name: 'Pickup',
    image: 'https://assets.leaseloco.com/inspiration/pick-ups/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/pick-ups/foreground.png',
    slug: '/van-leasing/pickup',
  },
  {
    id: 12,
    name: 'Prestige',
    image: 'https://assets.leaseloco.com/inspiration/prestige-cars/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/prestige-cars/foreground.png',
    slug: '/car-leasing/prestige-car-leasing',
  },
  {
    id: 13,
    name: 'SUV',
    image: 'https://assets.leaseloco.com/inspiration/suv/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/suv/foreground.png',
    slug: '/car-leasing/suv-car-leasing',
  },
  {
    id: 14,
    name: 'Tradesmen',
    image: 'https://assets.leaseloco.com/inspiration/traders/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/traders/foreground.png',
    slug: '/van-leasing/traders-van-leasing',
  },
]

export const COUNTRIES = [
  {
    id: 0,
    name: 'American',
    image: 'https://assets.leaseloco.com/inspiration/american/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/american/foreground.png',
    slug: '/car-leasing/american-car-leasing',
  },
  {
    id: 1,
    name: 'British',
    image: 'https://assets.leaseloco.com/inspiration/british/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/british/foreground.png',
    slug: '/car-leasing/british-car-leasing',
  },
  {
    id: 2,
    name: 'Chinese',
    image: 'https://assets.leaseloco.com/inspiration/chinese/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/chinese/foreground.png',
    slug: '/car-leasing/chinese-car-leasing',
  },
  {
    id: 3,
    name: 'French',
    image: 'https://assets.leaseloco.com/inspiration/french/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/french/foreground.png',
    slug: '/car-leasing/french-car-leasing',
  },
  {
    id: 4,
    name: 'German',
    image: 'https://assets.leaseloco.com/inspiration/german/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/german/foreground.png',
    slug: '/car-leasing/german-car-leasing',
  },
  {
    id: 5,
    name: 'Italian',
    image: 'https://assets.leaseloco.com/inspiration/italian/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/italian/foreground.png',
    slug: '/car-leasing/italian-car-leasing',
  },
  {
    id: 6,
    name: 'Japanese',
    image: 'https://assets.leaseloco.com/inspiration/japanese/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/japanese/foreground.png',
    slug: '/car-leasing/japanese-car-leasing',
  },
  {
    id: 7,
    name: 'Korean',
    image: 'https://assets.leaseloco.com/inspiration/korean/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/korean/foreground.png',
    slug: '/car-leasing/korean-car-leasing',
  },
  {
    id: 8,
    name: 'Spanish',
    image: 'https://assets.leaseloco.com/inspiration/spanish/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/spanish/foreground.png',
    slug: '/car-leasing/spanish-car-leasing',
  },
  {
    id: 9,
    name: 'Swedish',
    image: 'https://assets.leaseloco.com/inspiration/swedish/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/swedish/foreground.png',
    slug: '/car-leasing/swedish-car-leasing',
  },
]

export const HOMEPAGE = [
  {
    id: 1,
    name: 'Family cars',
    image: 'https://assets.leaseloco.com/inspiration/family-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/family-car/foreground.png',
    slug: '/car-leasing/family-car-leasing',
  },
  {
    id: 2,
    name: 'Dog friendly',
    image: 'https://assets.leaseloco.com/inspiration/dog-friendly/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/dog-friendly/foreground.png',
    slug: '/car-leasing/dog-friendly',
  },
  {
    id: 3,
    name: 'Fuel-efficient cars',
    image: 'https://assets.leaseloco.com/inspiration/fuel-efficient/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/fuel-efficient/foreground.png',
    slug: '/car-leasing/fuel-efficient',
  },
  {
    id: 4,
    name: 'Cheap car leasing',
    image: 'https://assets.leaseloco.com/inspiration/cheap-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/cheap-car/foreground.png',
    slug: '/car-leasing/cheap-car-leasing',
  },
  {
    id: 5,
    name: 'City cars',
    image: 'https://assets.leaseloco.com/inspiration/city-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/city-car/foreground.png',
    slug: '/car-leasing/city-cars',
  },
  {
    id: 6,
    name: 'High mileage deals',
    image: 'https://assets.leaseloco.com/inspiration/high-mileage-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/high-mileage-car/foreground.png',
    slug: '/car-leasing/high-mileage-car-leasing',
  },
  {
    id: 7,
    name: 'German',
    image: 'https://assets.leaseloco.com/inspiration/german/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/german/foreground.png',
    slug: '/car-leasing/german-car-leasing',
  },
  {
    id: 8,
    name: 'SUV',
    image: 'https://assets.leaseloco.com/inspiration/suv/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/suv/foreground.png',
    slug: '/car-leasing/suv-car-leasing',
  },
  {
    id: 9,
    name: 'Business professionals',
    image: 'https://assets.leaseloco.com/inspiration/business/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/business/foreground.png',
    slug: '/car-leasing/business-professionals',
  },
  {
    id: 10,
    name: 'Performance cars',
    image: 'https://assets.leaseloco.com/inspiration/performance-car/background.png',
    imageAlt: 'https://assets.leaseloco.com/inspiration/performance-car/foreground.png',
    slug: '/car-leasing/performance-car-leasing',
  },
]

export const CATEGORY_COLORS = [
  'orange',
  'pink',
  'blue',
  'black',
  'maroon',
  'purple',
  'green',
  'lilac',
]

import { ROUTES } from '@/lib/routes'
import { CollectionCard } from '@/components/styleguide/Card/Collection'
import { HOMEPAGE } from '@/lib/inspiration-categories'
import { Carousel } from '@/components/styleguide/Homepage/Carousel'

export const Collections = () => {

  return (
    <Carousel
      header={
        <>
          <p>Get started</p>
          <h2>Most popular collections</h2>
        </>
      }
      scrollValue={797}
      moreLink={ROUTES.collectionsPage.url}
      moreLabel='lifestyle inspiration'
      sectionName='collections'
    >
      {HOMEPAGE.map(lifestyle => (
        <CollectionCard
          key={lifestyle.id}
          type="carousel"
          {...lifestyle}
        />
      ))}
    </Carousel>
  )
}
